import { Flex, Image, Heading } from '@chakra-ui/react';
import playStore from '@/public/assets/google-play.svg?url';
import { useMediaQuery } from '@chakra-ui/react';

export default function CTA() {
  const [isMediumW] = useMediaQuery('(min-width:420px) and (max-width:490px)');
  return (
    <Flex
      alignItems={'center'}
      mt={['20px', null, '0px']}
      flexWrap={'wrap'}
      gap={4}
    >
      <Heading
        maxW="300px"
        fontSize={['20px', null, '26px']}
        color="black"
        fontWeight={'semibold'}
        mr={['0px', '20px']}
        textAlign={['left']}
      >
        Download our Mobile app
      </Heading>
      <a
        href="https://play.google.com/store/apps/details?id=com.incash.money.earning
"
      >
        <Image src={playStore} alt="play store" w={['200px']} />
      </a>
    </Flex>
  );
}
